import React from 'react'
import './Caption.css'
function Caption({caption}) {
    return (
        <div className="Caption_style1">
            {caption}
            
        </div>
    )
}

export default Caption