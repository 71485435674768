import React, { useState } from 'react';
import './Consulting.css';
import SmartSlider from "react-smart-slider";
import HeaderContent from '../Products/HeaderContent';
import { Button } from '@material-ui/styles/';

const DummyCaption = ({ caption }) => (
    <div className="ResearchCaption_style">
      {caption}
    </div>
  )

function Consulting() {
    const slidesArray = [
        {
            url: "https://cdn.pixabay.com/photo/2017/10/14/17/32/cyber-security-2851201_960_720.jpg",
            childrenElem: <DummyCaption caption="Data Mining" />
            // (Optional) Set if you want to add any content on your slide
            
          },
          {
            url: "https://cdn.pixabay.com/photo/2017/07/06/03/00/electrical-2476782_960_720.jpg",
            childrenElem: <DummyCaption caption="Data Mining" />
          },
       
      ];

  
    return (
        <div className="Consulting_container">
                <SmartSlider
                    slides={slidesArray}
                    buttonShape="round" // round or square
                        />
             
             <div className="Header_cards">

                <HeaderContent
                  image="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4z47F?ver=ddde&q=90&m=6&h=201&w=358&b=%23FFFFFFFF&l=f&o=t&aim=true"
                  title="Unlock creative teams"
                  paragraph="Create a work environment that fuels employee engagement and increases productivity—resulting in a culture of continuous innovation that drives business agility and accelerates transformation."
                />
                <HeaderContent
                  image="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4z47E?ver=26e7&q=90&m=6&h=201&w=358&b=%23FFFFFFFF&l=f&o=t&aim=true"
                  title="Transform on your terms"
                  paragraph="Enable people to do their best work with unified relationships, processes, and data. Gain actionable insights with Microsoft’s leading intelligent technology. And as your business changes, thrive with solutions expressly built for change. Unlock next."
                />
                <HeaderContent
                  image="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4yYL8?ver=2f48&q=90&m=6&h=201&w=358&b=%23FFFFFFFF&l=f&o=t&aim=true"
                  title="Realize your full potential"
                  paragraph="Differentiate yourself and reduce time to market by boosting digital productivity. Our innovative Service offerings will increase organizational agility and make it easier to scale exponentially."
                />
                <HeaderContent
                  image="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4z47G?ver=9c7f&q=90&m=6&h=201&w=358&b=%23FFFFFFFF&l=f&o=t&aim=true"
                  title="Accelerate growth"
                  paragraph="What if you could know the future, forecast effectively, and improve decision-making across your entire organization? Applying AI to your data allows you to do all of this and Microsoft Consulting Services how to help you do this globally and at scale."
                />




</div>
        </div>
    )
}

export default Consulting
