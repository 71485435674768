import React from 'react';
import './Services.css';
import SmartSlider from "react-smart-slider";
import Midcontent from '../Midcontent/Midcontent.js';
import Caption from '../Caption/Caption.js';

function Services() {
    const slidesArray = [
        {
            url: "https://i.imgur.com/t2a1zLi.jpg",
            childrenElem: <Caption caption="Data Visualisation" />
          },
        {
            url: "https://cdn.pixabay.com/photo/2016/11/30/20/58/programming-1873854_960_720.png",
     
            // (Optional) Set if you want to add any content on your slide
            childrenElem: <Caption caption="Web Development" />
          },
          {
            url: "https://cdn.pixabay.com/photo/2015/05/28/14/53/ux-788002_960_720.jpg",
            childrenElem: <Caption caption="App Development" />
          },
          
      ];
    return (
        <div className="Services_container">
                        <SmartSlider
                    slides={slidesArray}
                    buttonShape="round" // round or square
                        />
             <div className="Content_row">
                    <Midcontent
                    title="Web Development"
                    image="https://image.flaticon.com/icons/svg/867/867644.svg"
                    paragraph="One stop web designing company to strengthen your online presence"
                    />
                    <Midcontent
                    title="Mobile App Development"
                    image="https://image.flaticon.com/icons/svg/1085/1085791.svg"
                    paragraph="Our mobile app development strategy is simple, make life easy for users and they are going to come for more.Iterating on the Minimum Viable Product our developers 
                    and strategists keep on refining mobile applications for perfection."
                    />
                </div>

         </div>
    )
}

export default Services
